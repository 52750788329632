<template>
  <v-main>
    <AppBarPortal />
    <div class="mainContent">
      <div class="container-fluid customPadding pt-5 pb-5">
        <div class="row aen_lr_padding">
          <div class="col-sm-12 pt-3 pb-3">
            <h3>Όροι Χρήσης</h3>
            Εάν συνεχίσετε την πλοήγηση και χρησιμοποιείτε αυτόν τον δικτυακό
            τόπο, τότε συμφωνείτε με την συμμόρφωση προς τους όρους και συνθήκες
            χρήσης, που υπαγορεύουν την σχέση χρήσης του δικτυακού τόπου από
            εσάς.
            <p></p>
            <p>
              Η χρήση του δικτυακού τόπου υπόκειται στους παρακάτω όρους χρήσης:
            </p>
            <ul>
              <li>
                Αυτός ο δικτυακός τόπος περιέχει στοιχεία που ανήκουν στο
                Υπουργείο Ναυτιλίας και Νησιωτικής Πολιτικής (εφεξής Υ.ΝΑ.Ν.Π).
              </li>
              <li>
                Το Υ.ΝΑ.Ν.Π λαμβάνει όλα τα απαραίτητα μέτρα για να εξασφαλιστεί
                η σωστή λειτουργία των ηλεκτρονικών υπηρεσιών που παρέχει. Το
                Υ.ΝΑ.Ν.Π δεν ευθύνεται για οποιαδήποτε ζημία (άμεση ή έμμεση,
                θετική ή αποθετική) που τυχόν θα υποστεί ο χρήστης του Δικτυακού
                Τόπου από τη χρήση ή την αδυναμία χρήσης αυτής, από
                καθυστερήσεις κατά τη χρήση αυτής, από λάθη ή παραλείψεις σε
                αυτή, από διακοπές της τηλεφωνικής γραμμής, βλάβες του δικτύου
                ηλεκτροδότησης ή για άλλους λόγους.
              </li>
              <li>
                Το Υ.ΝΑ.Ν.Π δεν φέρει ευθύνη για τη διαθεσιμότητα, το
                περιεχόμενο, την πολιτική προστασίας προσωπικών δεδομένων και
                την ποιότητα των υπηρεσιών άλλων δικτυακών τόπων στους οποίους ο
                χρήστης έχει πρόσβαση μέσω της δικής του δικτυακής πύλης (από
                συνδέσμους – links).
              </li>
              <li>
                Οι πληροφορίες που εμφανίζονται στον ιστότοπο αναδημοσιεύονται
                μετά από συνεννόηση με τις αρμόδιες Διευθύνσεις ενώ καταβάλλεται
                κάθε προσπάθεια για τη διασφάλιση της ακρίβειας των πληροφοριών
                που δημοσιεύονται. Ωστόσο, το Υ.ΝΑ.Ν.Π δεν ευθύνεται για τυχόν
                ορθογραφικά ή άλλου είδους λάθη κατά την αναδημοσίευση των εν
                λόγω πληροφοριών, καθώς και για οποιαδήποτε αρνητική συνέπεια ή
                ζημία που τυχόν θα υποστεί ο χρήστης του ιστοτόπου ως απόρροια
                χρήσης των πληροφοριών αυτών.
              </li>
              <li>
                Η συγκεκριμένη ιστοσελίδα χρησιμοποιεί μόνον τα cookies που
                είναι απαραίτητα για τη λειτουργία της. Τα cookies είναι μικρά
                αρχεία κειμένου που αποθηκεύονται στο σκληρό δίσκο κάθε
                επισκέπτη/ χρήστη και περιλαμβάνουν μη προσωπικά στοιχεία
                αναγνώρισης των χρηστών του διαδικτυακού τόπου και δεν λαμβάνουν
                γνώση οποιουδήποτε εγγράφου ή αρχείου του υπολογιστή του.
              </li>
            </ul>
          </div>
          <!--
        <div class="col-xxl-4  col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <img class="img-fluid" style="width: -webkit-fill-available;" src="./images/aen_map.jpg" alt="Σύστημα Ηλεκτρονικής Διαχείρισης Σπουδών Ακαδημιών Εμπορικού Ναυτικού (ΑΕΝ)">
        </div>
        --></div>
      </div>
    </div>
    <AppFooterPortal />
  </v-main>
</template>

<script>
import AppBarPortal from "@/components/AppBarPortal.vue";
import AppFooterPortal from "@/components/AppFooterPortal.vue";

export default {
  name: "OroiXrisis",
  components: { AppFooterPortal, AppBarPortal },
};
</script>

<style scoped>
.aen_lr_padding {
  padding-left: 6rem;
  padding-right: 6rem;
}

.mainContent {
  padding-bottom: 100px;
}

@media only screen and (max-width: 1199px) {
  .aen_lr_padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  footer .row div {
    text-align: center;
  }

  .mainContent img {
    padding-top: 20px;
  }
}

dl,
ol,
ul {
  margin-bottom: 0rem;
}

span {
  float: left;
  clear: left;
}

.mainContent li {
  padding-top: 10px;
}

footer ul li a {
  font-size: 0.85rem !important;
}

.mainContent a {
  color: #2e4b93;
}

.mainContent p {
  text-align: justify;
}

.customPadding {
  padding: 0 120px;
}

@media (max-width: 1400px) {
  .customPadding {
    padding: 0 50px;
  }
}
</style>
